// This file is used by our deployment pipeline. The placeholders will be replaced by actual values from
// variables in Github Environments.

import type { Environment } from './environment.type';

export const environment: Environment = {
  production: true,
  appUiUrl: 'https://dev.hs.squadbox.ai',
  appApiUrl: 'https://api.dev.hs.squadbox.ai',
  refreshTokensTimerDue: +'300000',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Will be replaced by valid values during deployment
  speechToTextProvider: 'azure',
  azureSpeechToTextRegion: 'canadacentral',
  sentry: {
    dsn: 'https://c7b7c43c20b97fdf8360460bfe7e50ee@o936403.ingest.us.sentry.io/4508202231726080',
    environment: 'hs-dev',
    release: 'squadbox-3dd9e77d85340bc3f9d8c85d9d381c6ec1696600',
  },
  launchDarkly: {
    envKey: '67250f10f67eab080218073a',
    context: {
      kind: 'multi',
      user: {
        anonymous: true,
      },
      client: {
        key: 'hs',
        name: 'hs',
      },
      device: {
        anonymous: true,
      },
      browser: {
        anonymous: true,
      },
    },
    options: {
      bootstrap: 'localStorage',
      application: {
        id: 'squadbox-ui-hs-dev',
        version: 'squadbox-3dd9e77d85340bc3f9d8c85d9d381c6ec1696600',
      },
    },
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Will be replaced by valid values during deployment
  defaultLanguage: 'en',
  assistantPromptMaxLength: +'4000',
};
