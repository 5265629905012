import {
  EnvironmentProviders,
  InjectionToken,
  Provider,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { environment } from '../../environments/environment';
import { FeatureFlagsActions } from './store/feature-flags.actions';
import { FeatureFlagsState } from './store/feature-flags.reducer';

export type LaunchDarklyClient = LaunchDarkly.LDClient;
export type LaunchDarklyContext = LaunchDarkly.LDContext;
export type LaunchDarklyFlagValue = LaunchDarkly.LDFlagValue;
export type LaunchDarklyFlagSet = LaunchDarkly.LDFlagSet;

export const LAUNCH_DARKLY_CLIENT = new InjectionToken<LaunchDarklyClient>(
  'LAUNCH_DARKLY_CLIENT'
);

/**
 * How to configure LaunchDarkly SDKs to send application metadata to LaunchDarkly:
 * https://docs.launchdarkly.com/sdk/features/app-config
 */
const ldClient: LaunchDarklyClient = LaunchDarkly.initialize(
  environment.launchDarkly.envKey,
  environment.launchDarkly.context,
  environment.launchDarkly.options
);

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
export const provideLaunchDarklyInitializer = (): EnvironmentProviders =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  provideAppInitializer(() => {
    const initializerFn = (
      (featureFlagStore: Store<FeatureFlagsState>) => () =>
        featureFlagStore.dispatch(FeatureFlagsActions.initClient())
    )(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      inject(Store)
    );
    return initializerFn();
  });

export function provideLaunchDarklyClient(): Provider {
  return {
    provide: LAUNCH_DARKLY_CLIENT,
    useValue: ldClient,
  };
}
